import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import PIUrquizaPage from "../../components/PIUrquiza";
import ProjectsHeader from "../../components/Shared/ProjectsHeader";
import { getSeo } from "../../services/SEO";
import Seo from "../../components/Shared/SEO/Seo";
import ContactButton from "../../components/Shared/ContactButton";
import WhatsAppButton from "../../components/Shared/WhatsAppButton";
import ProjectsFooter from "../../components/Shared/ProjectsFooter";
import { scaleText } from "../../services/utils";
import Footer from "../../components/Shared/Footer";

const PIUrquizaTemplate = ({ data }) => {
  const {
    siteConfig,
    page: { tabs, slug },
  } = data;
  const { seo, config } = tabs;
  seo.slug = slug;

  const seoContent = getSeo(siteConfig, seo);

  const colors = {
    primary: config.colors.primaryColor.hex,
    secondary: config.colors.secondaryColor.hex,
    contrast: config.colors.contrastColor.hex,
  };

  useEffect(() => {
    scaleText();
    if (typeof window !== `undefined`)
      window.addEventListener("resize", scaleText, false);
  }, []);

  return (
    <>
      <Seo content={seoContent} language={siteConfig.lang} />
      <Helmet>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDVxV-vKNTgOv589d3GyHbvwtkKKrRzk_w&v=3.exp" />
      </Helmet>
      <ProjectsHeader
        projectName={seo.slug.current}
        logo={config.logoHeader.asset.url}
        address={config.address}
        siteConfig={siteConfig}
      />
      <main>
        <PIUrquizaPage
          colors={colors}
          projectName={seo.slug.current}
          siteConfig={siteConfig}
        />
      </main>
      <ContactButton projectName={seo.slug.current} />
      <WhatsAppButton
        projectName={seo.slug.current}
        whatsApp={siteConfig.whatsapp}
      />
      {/* <ProjectsFooter
        bgColor={colors.primary}
        textColor={colors.contrast}
        logo={`../${seo.slug.current}/${seo.slug.current}-logo-simple.svg`}
        developer="MARIO SERESEVSKY"
        siteConfig={siteConfig}
      /> */}
      <Footer siteConfig={siteConfig} />
    </>
  );
};

export const PIUrquizaPageQuery = graphql`
  query PIUrquizaPageQuery($slug: String!) {
    siteConfig: sanitySiteConfig {
      lang
      title
      description
      url
      address
      email
      phone
      whatsapp
      instagram
      linkedin
      mainImage {
        asset {
          url
        }
      }
    }
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      tabs {
        seo {
          meta_title
          meta_description
          article
          seo_image {
            asset {
              url
            }
          }
          includeInSitemap
        }
        config {
          logoHeader {
            asset {
              url
            }
          }
          address
          colors {
            primaryColor {
              hex
            }
            secondaryColor {
              hex
            }
            contrastColor {
              hex
            }
          }
        }
      }
    }
  }
`;

export default PIUrquizaTemplate;
