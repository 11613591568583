import React from "react";
import { PageProps } from "../../interfaces/projects";
import ProhectsHero from "../Shared/ProjectsHero";
import ProjectsHighlights from "../Shared/ProjectsHighlights";
import ProjectsAmenities from "../Shared/ProjectsAmenities";
import ProjectsUbicacion from "../Shared/ProjectsUbicacion";
import ProjectsSearchProperties from "../Shared/ProjectsSearchProperties";
import ProjectsFinanciacion from "../Shared/ProjectsFinanciacion";
import ProjectBrouchure from "../Shared/ProjectsBrouchure";

const PIUrquizaPage: React.FC<PageProps> = ({
  colors,
  projectName,
  siteConfig,
}) => {
  return (
    <div className="piurquiza-page">
      <ProhectsHero
        imgs={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644974074/Codevelopers/web/Pi%20Urquiza/Hero/1A_HEADER_Palabra_LUMINOSIDAD_b6wsam.jpg",
            label: "Luminosidad",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644974075/Codevelopers/web/Pi%20Urquiza/Hero/1B_HEADER_palabra_NATURALEZA_rsymah.jpg",
            label: "Naturaleza",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644974074/Codevelopers/web/Pi%20Urquiza/Hero/1C_HEADER_Palabra_Disen%CC%83o_wjwjql.jpg",
            label: "Diseño",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644974076/Codevelopers/web/Pi%20Urquiza/Hero/1D_HEADER_palabra_Relax_bugskn.jpg",
            label: "Relax",
          },
        ]}
      />
      <ProjectBrouchure
        title="UN HÁBITAT PARA<br/>UNA VIDA EN SANO<br />Y FELIZ EQUILIBRIO"
        subtitle="Semipisos de 3 y 4 ambientes<br/> en el corazón de Villa Urquiza."
        brouchureSrc={
          "https://prismic-io.s3.amazonaws.com/codevelopers/92807b8b-94a2-4d2d-8bc8-f41abb6eb55f_P%C4%ABUrquiza+Brochure4_baja+%281%29.pdf"
        }
        brouchureSrcMobile={""}
        colors={colors}
        images={{
          picture: "",
          main: "https://res.cloudinary.com/mosconi-digital/image/upload/v1645231504/Codevelopers/web/Pi%20Urquiza/2b_chica_a_la_derecha_eci5zh_kae6je.jpg",
          primary:
            "https://res.cloudinary.com/mosconi-digital/image/upload/v1645231504/Codevelopers/web/Pi%20Urquiza/2A_primeras_fotos_a_la_derecha_w1moxr_wpz5jd.jpg",
          last: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646429388/Codevelopers/web/Pi%20Urquiza/Copia_de_PCAM_010_Post9_rp3tqk.jpg",
          background: `/${projectName}/${projectName}-logo-simple-grey.svg`,
        }}
      />
      <ProjectsSearchProperties
        colors={colors}
        project={projectName}
        images={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975709/Codevelopers/web/Pi%20Urquiza/Unidades/3A_UNIDADES_adduej.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3B_UNIDADES_tesr3e.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3C_UNIDADES_r2gcha.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3D_UNIDADES_pqh8le.jpg",
          },
        ]}
        searchForm={{
          orientacion: {
            title: "Orientación",
            options: [
              {
                label: "Frente",
                value: "frente",
              },
              {
                label: "Contrafrente",
                value: "contrafrente",
              },
              {
                label: "Piso",
                value: "piso",
              },
              {
                label: "Todas las orientaciones",
                value: "all",
              },
            ],
          },

          exterior: {
            title: "Espacio exterior",
            options: [
              {
                label: "Balcón",
                value: "balcón",
              },
              {
                label: "Balcón terraza",
                value: "balcón terraza",
              },
              {
                label: "Terraza propia",
                value: "terraza propia",
              },
              {
                label: "Todas los espacios",
                value: "all",
              },
            ],
          },

          ambientes: {
            title: "Cantidad de ambientes",
            options: [
              {
                label: "3 Ambientes",
                value: "3",
              },
              {
                label: "4 Ambientes",
                value: "4",
              },
              {
                label: "Todos los ambientes",
                value: "all",
              },
            ],
          },
        }}
      />
      <ProjectsAmenities
        title={
          "LOS AMENITIES Y ESA PLACENTERA SENSACIÓN<br />DE ESTAR DONDE UNO QUIERE ESTAR"
        }
        amenities={[
          {
            icon: "terraza-verde",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680841/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_copia_2_umwgg1.jpg",
            title: "Terraza verde",
            description:
              "Les presentamos el punto más alto de la colmena y el disfrute donde además abunda el verde. La vegetación rodea la pileta de los chicos y el jacuzzi para disfrutar envueltos en naturaleza e intimidad. Deck, duchas y paz. Amén.",
          },
          {
            icon: "piscina",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680841/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_copia_wzgxxg.jpg",
            title: "Piscina<br />para niños",
            description:
              "Los más chicos también tienen sus espacios para el disfrute en Pī Urquiza. Juegos, chapuzones y risas, la postal de un verano ideal en la ciudad.",
            // objectPosition: "left",
          },
          {
            icon: "jacuzzi",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680839/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_mv3szv.jpg",
            title: "Jacuzzi<br />para grandes",
            description:
              "Mirar desde arriba, no desde el lugar de soberbia sino desde el relax. Momentos para uno mismo en el punto más alto de Pi Urquiza.",
            objectPosition: "right",
          },
          {
            icon: "huerta",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1645744343/Codevelopers/web/Pi%20Urquiza/Amenities/huerta_qj5xth.png",
            title: "Huerta<br />comunitaria",
            description:
              "A la huerta la reservamos para la terraza de uso común, porque lo compartido sabe más rico. Una alimentación más consciente, es parte de la experiencia wellness que Pī trae para vos.",
          },

          {
            icon: "estacionamiento-bicicleta",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1645744590/Codevelopers/web/Pi%20Urquiza/Amenities/estacionamiento.bicicleta_jnoeuh.jpg",
            title: "Estacionamiento<br />para bicicleta",
            description:
              "La movilidad sustentable es un must en el estilo de vida que buscás, y en Pī nada de eso falta. Todo listo para los Bicilovers que eligen el transporte más eco-friendly.",
          },
        ]}
        colors={colors}
      />
      <ProjectsHighlights
        colors={colors}
        items={[
          {
            title: "SUSTENTABILIDAD<br />Y ECO RIEGO",
            description:
              "Como fans de nuestro planeta diseñamos los espacios con materiales de bajo impacto ambiental en todo el edificio. Instalamos energías renovables en espacios comunes, purificadores de agua en cocinas y duchas, estacionamiento de bicis y cocheras con cargadores para autos eléctricos.<br />Los balcones se rebalsan de verdes con la complicidad de riegos automáticos. Acompañan las composteras individuales apostando a una conciencia y mundo más puro.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975569/Codevelopers/web/Pi%20Urquiza/Highlights/5_PRIMER_CI%CC%81RCULO_qhngyv.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
          {
            title: "HOGAR,<br />INTELIGENTE HOGAR",
            description:
              "Todas nuestras unidades tienen apertura de cortinas automáticas, cerraduras electrónicas y climatización con sistema de aire acondicionado VRV.<br />El touch: Vidrios DVH con aislación térmica y acústica para poder estar solos en comunidad. Al fin de cuentas, el vivir bien también es una decisión inteligente.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646233880/Codevelopers/web/Pi%20Urquiza/Highlights/5_segundo_ci%CC%81rculo_r9mpkz.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
          {
            title: "BAÑO SMART",
            description:
              "La magia de pisar calentito cuando salís de la ducha no tiene precio. Le decimos sí a la losa radiante y sí a envolverte en el toallón recién salido del secador de toallas eléctrico. Todos nuestros baños son inteligentes. Incluyen desde inodoros smart hasta espejos con desempañador automático. Espejito espejito, dime cuál es el baño más bonito.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646239411/Codevelopers/web/Pi%20Urquiza/Highlights/5_CIRCULO_3_hyib0p.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
        ]}
      />
      <ProjectsUbicacion
        title="ALMA DE BARRIO,<br />ESPÍRITU METROPOLITANO."
        description="Quien vivió en Villa Urquiza conoce su magia, y quien no,<br />rápidamente caerá en su encanto de espacios verdes,<br />veredas con historia y sabores artesanales."
        colors={colors}
        image={""}
        video={{
          url: "https://res.cloudinary.com/sbc/video/upload/f_auto/v1652880496/codevelopers/Turista_short_g4yhbw_wsb86r.mp4",
          urlSm:
            "https://res.cloudinary.com/sbc/video/upload/f_auto/v1652880447/codevelopers/Turista_sm_short_ioqq8f_hmhxn2.mp4",
          poster:
            "https://res.cloudinary.com/mosconi-digital/image/upload/f_auto/v1646240071/Codevelopers/web/Pi%20Urquiza/Turista_gim5ox_jcld0e.jpg",
        }}
        location="Andonaegui 2042, Villa Urquiza, Buenos Aires"
        geo={{
          lat: -34.57966928632486,
          lng: -58.48634771862823,
        }}
      />
      <ProjectsFinanciacion
        title="UNIDADES FINANCIADAS<br />EN CUOTAS Y EN PESOS"
        description="Semipisos de 3 ambientes y pisos de 4 listos para disfrutar tu vida. <br/>Estás listo para vivir tu casa, la familia, tu pareja, los<br/> amigos, el verde, todo. Entonces estás listo para Pī Urquiza."
        colors={colors}
        image={
          "https://res.cloudinary.com/mosconi-digital/image/upload/v1646429315/Codevelopers/web/Pi%20Urquiza/Financiacion/2C_chica_a_la_derecha_edwmdr_bavnjp_gfvy09.jpg"
        }
      />
    </div>
  );
};

export default PIUrquizaPage;
